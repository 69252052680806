/* .CodeMirror { */
/*   font-size: 16px; */
/*   height: auto; */
/* } */

/* .CodeMirror-lines { */
/*   /1* padding: 0.5rem; *1/ */
/* } */

/* .CodeMirror pre.CodeMirror-placeholder { */
/*   color: #999; */
/* } */

.remote-caret {
  position: relative;
  border-left: 2px solid black;
  margin-left: -1px;
  margin-right: -1px;
  box-sizing: border-box;
}
.remote-caret > div {
  position: absolute;
  top: -1.05em;
  left: -2px;
  font-size: 0.6em;
  background-color: rgb(250, 129, 0);
  font-family: serif;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  user-select: none;
  color: white;
  padding-left: 2px;
  padding-right: 2px;
  z-index: 3;
  transition: opacity 0.3s ease-in-out;
}
.remote-caret.hide-name > div {
  transition-delay: 0.7s;
  opacity: 0;
}
.remote-caret:hover > div {
  opacity: 1;
  transition-delay: 0s;
}
